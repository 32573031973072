import React from "react";
import PropTypes from 'prop-types';
import { MdDeleteForever } from "react-icons/md";
import { logOutUrl } from '../../common/utils';
import { fetchData } from '../../common/commonRequests';
import coworkerAPI from '../../../services/coworkerAPI';

const logOutCallback = () => (window.location.href = logOutUrl);
const CoworkerAcceptedRequest = ({
  translate,
  workSpaceEmail,
  workSpaceId,
  setIsLoading,
  setError
}) => {

  const handleRemove = () => { // Add modal to confirm
    fetchData(
      setIsLoading,
      setError,
      coworkerAPI.removeCoworker,
      workSpaceId,
      logOutCallback
    );
  };

  return (
    <div className="workspaceWram">
      <p>{workSpaceEmail}</p>
      <MdDeleteForever
        className="controls-icons"
        size={'1.5em'}
        onClick={handleRemove}
        title={translate('remove')}
      />
    </div>
  );
};

CoworkerAcceptedRequest.propTypes = {
  translate: PropTypes.func.isRequired,
  workSpaceEmail: PropTypes.string.isRequired,
  workSpaceId: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default CoworkerAcceptedRequest;
