import axios from 'axios';

const settingsApi = {
  addNewProperty: async (data) => {
    const res = await axios.post('/api/settings/addNewProperty', data)

    return res.data || [];
  },

  getAllProperties: async () => {
    const res = await axios.get('/api/settings/get-all-properties');

    return res.data || [];
  },

  removeProperty: async (id) => {
    const res = await axios.delete('/api/settings/remove-property', { data: { propId: id } });
    
    return res.data || [];
  },

  editProperty: async (propId, data) => {
    const res = await axios.patch('/api/settings/edit-property', { propId, data });

    return res.data || [];
  },

  removeAllProperties: async () => {
    const res = await axios.delete('/api/settings/remove-all-properties');
    
    return res.data || [];
  },
};

export default settingsApi;
