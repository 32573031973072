import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import { usePropertyContext } from '../../contexts/propertyProvider';
import {
  getReservedDatesInOneProperty,
  isSelectedDatesAvailable
} from './utils';

const DateRangePicker = ({
  setDataRange,
  dataRange, // TODO: !!!!!!!! dataRange changed  from [{}] to {}
  translate,
  isComponentDisabled,
  propertyId,
  orders,
  timeLineLoadedDataRange,
  isOrderEdit,
  currentlyEditedOrderData,
  setIsDateAvailable,
  isDateAvailable
}) => {
  const { data: propertyData } = usePropertyContext();

  const [disabledDates, setDisabledDates] = useState([]);

  const handleMonthChange = (selectedMonthDate) => {
    // console.log("--MonthChange DATA--", selectedMonthDate)
  };

  // Check selected range availability
  useEffect(() => {
    if (orders?.length && dataRange.endDate && dataRange.startDate) {
      setIsDateAvailable(
        isSelectedDatesAvailable(dataRange.startDate, dataRange.endDate, orders, isOrderEdit, currentlyEditedOrderData)
      );
    }
  }, [propertyId, orders, dataRange]);

  useEffect(() => {
    if (dataRange.endDate) {
      const isEndDateSelected = dataRange.startDate.getTime() === dataRange.endDate.getTime();
      setDisabledDates(getReservedDatesInOneProperty(
        orders,
        propertyId,
        isEndDateSelected,
        isOrderEdit,
        currentlyEditedOrderData
        ));
    }
  }, [dataRange, propertyId]);

  if (propertyData?.length === 0) {
    return;
  };

  return (
    <div className="calendar-wrap">
      {
        isComponentDisabled ?
        (
          <div className="disable-calendar-wrap">
            <div>&nbsp;</div>
            <p>{translate('selectPropertyToSeeAvailableDates')}</p>
          </div>
        )
        : null
      }
      <DateRange
        date={new Date()}
        onChange={item => setDataRange(item.selection)}
        onShownDateChange={handleMonthChange}
        ranges={[dataRange]}
        editableDateInputs={false} //dates can be edited in the Calendar's input fields
        moveRangeOnFirstSelection={false} // Disable moving the first range
        minDate={timeLineLoadedDataRange.dateFrom} // disable bere today date selection
        maxDate={timeLineLoadedDataRange.dateTo}
        disabledDates={disabledDates}
        locale={translate('datePickerObj')}
        rangeColors={['#67afc6']}
        // startDatePlaceholder={"checkIn"}
        // endDatePlaceholder={translate('checkoutDate')}
        // showSelectionPreview={false}
        // showPreview={false}
        // preventSnapRefocus={false} //prevents unneceessary refocus of shown range on selection
        // showMonthArrow={false}
        // staticRanges={disabledDates}
        // inputRanges={disabledDates}
        // editable={false}
        // dayContentRenderer={renderDayContent} // Custom date renderer
      />
      {!isDateAvailable ? <div className="warningMessage">{translate('thisDatesAreNotAvailable')}</div> : null}
    </div>
  );
};

DateRangePicker.propTypes = {
  setDataRange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dataRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }).isRequired,
  isComponentDisabled: PropTypes.bool,
  propertyId: PropTypes.string,
  timeLineLoadedDataRange: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date)
  }).isRequired,
  setIsDateAvailable: PropTypes.func.isRequired
};

export default DateRangePicker;
