import React, { useState, useEffect } from "react";
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';
import coworkerAPI from '../../../services/coworkerAPI';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import { useCoworkerRequestContext } from '../../../contexts/coworkerRequestProvider';
import { coworkerRequestStatus, logOutUrl } from '../../common/utils';
import { fetchData } from '../../common/commonRequests'; 
import CoworkerRequestModalMessage from './CoworkerRequestModalMessage';
import ModalComponent from '../../common/ModalComponent';

// Component displays the first request with PENDING status.
// The second request will be displayed only when the first one will be accepted/declined.
const CoworkerRequestModal = () => {
  const { translate } = useUserDataContext();
  const {
    data: allCoworkerRequests,
    isLoading: isLoadingCoworkerData,
    error: errorCoworkerData,
    isApprovedCoworker,
    workSpaceEmail
  } = useCoworkerRequestContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);

  const pendingRequests = allCoworkerRequests?.filter(item => item.status === coworkerRequestStatus.pending);

  const approvedStatusData = {
    id: pendingRequests?.[0]?.id,
    data: {
      status: coworkerRequestStatus.approved
    }
  };

  const rejectedStatusData = {
    id: pendingRequests?.[0]?.id,
    data: {
      status: coworkerRequestStatus.rejected
    }
  };

  const handleReload = () => {
    window.location.reload();
  };


const showConfirmAcceptanceModal = () => {
  setIsSuccessModal(true);
};

  const acceptHandler = () => {
    setIsModal(false);
    fetchData(
      setIsLoading,
      setError,
      coworkerAPI.editCoworker,
      approvedStatusData,
      showConfirmAcceptanceModal
    );
  };

  const rejectHandler = () => {
    fetchData(
      setIsLoading,
      setError,
      coworkerAPI.editCoworker,
      rejectedStatusData,
      handleReload
    );
  };

  const successModalHandler = () => {
    window.location.href = logOutUrl;
  };
 
  // a New request comes when one request is already approved
  const isNewRequestWhenWorkspaceExist = () => (
    !!pendingRequests?.length && isApprovedCoworker
  );

  const requestedBy = allCoworkerRequests[0]?.requestedBy;
  const pendingRequestsLength = pendingRequests.length;

  useEffect(() => {
    if (pendingRequestsLength > 0) {
      setIsModal(true);
    }
  }, [requestedBy, pendingRequestsLength]);


  if (allCoworkerRequests.length === 0) {
    return
  };

  const firstModalButtonHandler = () => (
    isNewRequestWhenWorkspaceExist() ? rejectHandler() : acceptHandler()
  );

  const firstModalButtonName = isNewRequestWhenWorkspaceExist() ? 'decline' : 'accept';
  const secondaryModalButtonHandler = isNewRequestWhenWorkspaceExist() ? () => setIsModal(false) : rejectHandler; // TODO: rejectHandler() ???
  const secondaryModalButtonName = isNewRequestWhenWorkspaceExist() ? 'remindMeLater' : 'decline';

  return (
    <>
      <Loader 
        setIsLoading={isLoading || isLoadingCoworkerData}
      />
      <ErrorAlert
        error={error || errorCoworkerData}
      />
      <ModalComponent 
        ModalContent={translate('requestAccepted')}
        onSubmitButtonHandler={successModalHandler}
        onSubmitButtonName={translate('proceed')}
        show={isSuccessModal}
        handleClose={() => setIsSuccessModal(false)}
        hideCancelButton={true}
        type="WARNING"
      />
      <ModalComponent 
        ModalContent={
          <CoworkerRequestModalMessage
            translate={translate}
            isNewRequest={isNewRequestWhenWorkspaceExist}
            requesteBy={pendingRequests[0]?.requestedBy}
            workSpaceEmail={workSpaceEmail}
          />
        }
        onSubmitButtonHandler={firstModalButtonHandler}
        onSubmitButtonName={translate(firstModalButtonName)}
        secondaryButtonHandler={secondaryModalButtonHandler}
        secondaryButtonName={translate(secondaryModalButtonName)}
        hideCancelButton={true}
        show={isModal}
        handleClose={() => setIsModal(false)}
        type="WARNING"
      />
    </>
  );
};

export default CoworkerRequestModal;
