import React from "react";
import { Button } from "react-materialize";
import { useUserDataContext } from '../contexts/userDataProvider';
import { loginLink, logOutUrl } from './common/utils';

const EmailNotVerified = () => {
  const { translate } = useUserDataContext();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  };

  const backButton = {
    marginTop: 60
  };

  const backToLoginPage = {
    marginTop: 40
  };

  return (
    <div style={containerStyle}>
      <h3>{translate('emailNotVerified')}</h3>
      <p>{translate('clickOnVerifyEmail')}</p>
      <p>{translate('thanClickLogin')}</p>
      <Button
        style={backButton}
        node="button"
        type="submit"
        waves="light"
        onClick={() => window.location.href = loginLink}
      >
        {translate('login')}
      </Button>
      <a
        style={backToLoginPage}
        href={logOutUrl}>
          {translate('backToLoginPage')}
      </a>
    </div>
  );
};

export default EmailNotVerified;
