const datePickerObj = {
  localize: {
    month: (month) => [
      'січень',
      'лютий',
      'березень',
      'квітень',
      'травень',
      'червень',
      'липень',
      'серпень',
      'вересень',
      'жовтень',
      'листопад',
      'грудень'
    ][month],
    day: (day) => ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'][day]
  }
};

const translations = {
  datePickerObj: datePickerObj,
  displayFeedingOption: 'Показувати опцію харчування',
  logout: 'Вийти',
  changeLanguage: 'Change language',
  changeStatus: 'Змінити статус',
  userSettings: 'Налаштування користувача',
  selectPropertyToSeeAvailableDates: 'Виберіть житло, щоб побачити доступні дати',
  reserve: 'Резервувати',
  firstName: "Ім'я",
  surname: 'Прізвище',
  email: 'e-mail',
  phone: 'Телефон',
  feeding: 'Харчування',
  comments: 'Коментар',
  addFirstPropertyInSettings: 'Додайте першу опцію житла в налаштуваннях',
  title: 'Назва',
  costNight: 'Вартість/ніч',
  dateFrom: 'Дата від',
  dateTo: 'Дата до',
  clear: 'Очистити',
  january: 'Січень',
  february: 'Лютий',
  march: 'Березень',
  april: 'Квітень',
  may: 'Травень',
  june: 'Червень',
  july: 'Липень',
  august: 'Серпень',
  september: 'Вересень',
  october: 'Жовтень',
  november: 'Листопад',
  december: 'Грудень',
  jan: 'Січ',
  feb: 'Лют',
  mar: 'Бер',
  apr: 'Квіт',
  mayShort: 'Трав',
  jun: 'Черв',
  jul: 'Лип',
  aug: 'Серп',
  sep: 'Вер',
  oct: 'Жовт',
  nov: 'Лист',
  dec: 'Груд',
  sunday: 'Неділя',
  monday: 'Понеділок',
  tuesday: 'Вівторок',
  wednesday: 'Середа',
  thursday: 'Четвер',
  friday: 'П’ятниця',
  saturday: 'Субота',
  s: 'С',
  m: 'Пн',
  t: 'Вт',
  w: 'Ср',
  f: 'Пт',
  sun: 'Нд',
  mon: 'Пн',
  tue: 'Вт',
  wed: 'Ср',
  thu: 'Чт',
  fri: 'Пт',
  sat: 'Сб',
  errorMessageComon: 'Щось пішло не так! Будь ласка, спробуйте ще раз пізніше або зв’яжіться з нами.',
  yesRemove: 'Так, видалити',
  cancel: 'Скасувати',
  canNotBeEmpty: 'Не може бути порожнім',
  save: 'Зберегти',
  remove: 'Видалити',
  edit: 'Редагувати',
  name: "Ім'я",
  yes: 'Так',
  no: 'Ні',
  status: 'Статус',
  created: 'Створено',
  selectStatus: 'Вибрати статус',
  search: 'Знайти',
  nothingFound: 'Нічого не знайдено',
  description: 'Опис',
  add: 'Додати',
  addProperty: 'Додати першу опцію житла',
  manageProperties: 'Управління житловими об’єктами',
  notAvailable: 'Недоступно',
  available: 'Доступно',
  new: 'Новий',
  accommodated: 'Розміщений',
  cleaning: 'Прибирання',
  notActivatedMessage: 'Ваш обліуовий запис не активний',
  backToLoginPage: '< На головну',
  pageDoesNotExist: 'Сторінки з такою адресою не існує',
  login: 'Вхід',
  landingTitle: 'Просте рішення управління готельним комплексом для малог бізнесу',
  wrongNumberOrEmpty: 'Не вірне начення',
  authSessionHasExpired: 'Термін сесії завершився. Будь ласка, авторизуйтесь знову.',
  emailNotVerified: 'Неохідно підтвердити Email.',
  clickOnVerifyEmail: 'Ми щойно відправили вам електронний лист. Будь ласка, завершіть процес підтвердження перейшовши за посиланням надісланим у листі, та поверніться на цю сторінку.',
  thanClickLogin: 'Потім натисніть "Вхід"',
  totalCost: 'Кінцева ціна',
  totalCostEditable: 'Кінцева ціна (Можна змінити)',
  checkoutDate: 'Дата виїзду',
  costNightTotal: '- Ніч/Загальна',
  cost: 'Вартість',
  today: 'Сьогодні',
  thisReservationWillBeRemoved: 'Резервація буде видалена',
  thisDatesAreNotAvailable: 'Вибрані дати зайняті',
  allNightsCost: 'Ціна за всі ночі',
  editOrder: 'Редагування резервації',
  addNewOrder: 'Нова резервація',
  pleaseCheckCorrectness: 'Перевірте правильність',
  costPerNightChanged: 'Ціна за ніч змінилась. Перевірте загальну вартість',
  daysAmontChanged: 'Кількість ночей змінилась. Перевірте загальну вартість',
  grantAccess: 'Надати доступ',
  acceptedWorkspaceAccess: 'Доступ до робочого простору',
  role: 'Рівень доступу',
  manager: 'Менеджер',
  viewOnly: 'Тільки перегляд',
  accessGranted: 'Доступ надано:',
  wrongEmailFormat: 'Не правильний формат email',
  coworkerAlreadyAdded: 'Цей email вже додано',
  unpaid: 'Не оплачено',
  paid: 'Оплачено',
  partiallyPaid: 'Чавстково оплачено',
  PENDING: 'Не підтверджено',
  APPROVED: 'Прийнято',
  REJECTED: 'Відхилено',
  coworkerRequestMessage: 'Вам надіслано запит на керування замовленнями від',
  decline: 'Скасувати',
  accept: 'Прийняти',
  cancelBeforeAcceptRequest: 'Щоб прийняти цей запит, вам потрібно скасувати ваше поточне активне підключення з',
  inTheSettingsSection: 'в секції налаштувань.',
  remindMeLater: 'Нагадати пізніше',
  removeAccount: 'Видалити акаунт',
  removeAllRecords: 'Видалити дані',
  allRecordsWillBeRemoved: 'Усі записи будуть видалені.',
  yourAccountWillBeRemoved: 'Ваш акаунт буде видалено',
  thisNameAlreadyExist: 'Ця назва вже існує',
  proceed: 'Продовжити',
  requestAccepted: 'Запит успішно прийнято. Будь ласка, увійдіть знову, щоб побачити прийнятий робочий простір.',
  removeAcceptedWorkspaceToContinue: 'Видаліть наданий доступ до робочого простору щоб продовжити'
};

export default translations;
