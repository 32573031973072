const datePickerObj = {
  localize: {
    month: (month) => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ][month],
    day: (day) => ['San', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]
  }
};

const translations = {
  datePickerObj: datePickerObj,
  displayFeedingOption: 'Display feeding option',
  logout: 'Logout',
  changeLanguage: 'Change language',
  changeStatus: 'Change status',
  userSettings: 'User settings',
  selectPropertyToSeeAvailableDates: 'Select an Accommodation to see available dates',
  reserve: 'Reserve',
  firstName: 'First name',
  surname: 'Surname',
  email: 'Email',
  phone: 'Phone',
  feeding: 'Feeding',
  comments: 'Comments',
  addFirstPropertyInSettings: 'Add first Accommodation in the settings',
  title: 'Title',
  costNight: 'Cost/night',
  dateFrom: 'Date from',
  dateTo: 'Date To',
  clear: 'Clear',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  mayShort: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  s: 'S',
  m: 'M',
  t: 'T',
  w: 'W',
  f: 'F',
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  errorMessageComon: 'Something went wrong! Please try again later or contact us.',
  yesRemove: 'Yes, remove',
  cancel: 'Cancel',
  canNotBeEmpty: 'Can not be empty',
  save: 'Save',
  remove: 'Remove',
  edit: 'Edit',
  name: 'Name',
  yes: 'Yes',
  no: 'No',
  status: 'Status',
  created: 'Created',
  selectStatus: 'Select status',
  search: 'Search',
  nothingFound: 'Nothing found',
  description: 'Description',
  add: 'Add',
  addProperty: 'Add first accommodation',
  manageProperties: 'Manage accommodation',
  notAvailable: 'Not available',
  available: 'Available',
  new: 'New',
  accommodated: 'Accommodated',
  cleaning: 'Cleaning',
  notActivatedMessage: 'Your account is not Active',
  backToLoginPage: '< Back to login page',
  pageDoesNotExist: 'This page does not exist',
  login: 'Login',
  landingTitle: 'Effortless residential accommodation Management',
  wrongNumberOrEmpty: 'Wrong number or empty',
  authSessionHasExpired: 'The authentication session has expired. Please sign-in again.',
  emailNotVerified: 'Email verification is required',
  clickOnVerifyEmail: 'We have just sent you an email. Please complete the verification process by clicking the link provided and return to this page.',
  thanClickLogin: 'Than click Login',
  totalCost: 'Final cost',
  totalCostEditable: 'Final cost (Editable)',
  checkoutDate: 'Check-out date',
  costNightTotal: '- Night/Total',
  cost: 'Cost',
  today: 'Today',
  thisReservationWillBeRemoved: 'This reservation will be removed permanently',
  thisDatesAreNotAvailable: 'This dates are not available',
  allNightsCost: 'All nights cost',
  editOrder: 'Edit reservation',
  addNewOrder: 'New reservation',
  pleaseCheckCorrectness: 'Please check the correctness',
  costPerNightChanged: 'Cost per night changed. Check final cost',
  daysAmontChanged: 'Days amont changed. Check final cost',
  grantAccess: 'Grant access',
  acceptedWorkspaceAccess: 'Access to workspace',
  role: 'Role',
  manager: 'Manager',
  viewOnly: 'View only',
  accessGranted: 'Access granted:',
  wrongEmailFormat: 'Wrong email format or empty',
  coworkerAlreadyAdded: 'This email is already added',
  unpaid: 'Unpaid',
  paid: 'Paid',
  partiallyPaid: 'partially paid',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  coworkerRequestMessage: 'You have received request to manage an orders from',
  decline: 'Decline',
  accept: 'Accept',
  cancelBeforeAcceptRequest: 'To accept this request, you need to cancel your currently active connection with',
  inTheSettingsSection: 'in the Settings section.',
  remindMeLater: 'Remind me later',
  removeAccount: 'Remove account',
  removeAllRecords: 'Remove all records',
  allRecordsWillBeRemoved: 'All records will be removed.',
  yourAccountWillBeRemoved: 'Your account will be removed',
  thisNameAlreadyExist: 'This name is already exist',
  proceed: 'Proceed',
  requestAccepted: 'Request successfully accepted. Please relogin to see accepted workspace.',
  removeAcceptedWorkspaceToContinue: 'Remove accepted workspace access to continue'
};

export default translations;
