import React, { useState, useEffect } from "react";

const Loader = ({ setIsLoading }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(setIsLoading);
    }, [setIsLoading]);

    return (
      loading && (
        <div className="loader">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      )
    )
};

export default Loader;