import React from "react";

const Footer = () => {

  return (
    <div className="footer-wrap">

    </div>
  );
};

export default Footer;
