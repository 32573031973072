import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
    
const Alert = ({ variant, children, isAlert, dismissible, className }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(isAlert)

    if(dismissible) {
      const timeId = setTimeout(() => {
        setShow(false)
      }, 10000)
  
      return () => {
        clearTimeout(timeId)
      }
    }

  }, [isAlert]);

  if (!show) {
    return null;
  }

  return (
    <div className={`${className} alert alert-${variant}`}>
      <AiOutlineClose
        className='close-icon'
        onClick={() => setShow(false)}
        size={'1.5em'}
      />
      {children}
    </div>
  )
}

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.object.isRequired,
  isAlert: PropTypes.bool.isRequired,
  dismissible: PropTypes.bool
};

Alert.defaultProps = {
  variant: 'error',
  dismissible: true
};

export default Alert;