export const parseOrdersForTimeline = (orders) => {
  let parsedOrders = orders.map((order) => ({
    start: new Date(order.dateFrom),
    end: new Date(order.dateTo),  // end is optional
    content: order.firstName,
    group: order.propertyName,
    className: 'itemClassName',
    editable: true,
    createdAt: order.createdAt,
    costPerNight: order.costPerNight,
    email: order.email,
    firstName: order.firstName,
    id: order.id,
    phone: order.phone,
    propertyId: order.propertyId,
    status: order.status,
    totalCost: order.totalCost,
    updatedAt: order.updatedAt
  }))

  return (
    parsedOrders
  );
};

export const parseGroupForTimeline = (propertyData) => (
  propertyData.map((property) => ({
    id: property.title, // need to be title to work with edit item group autoselect
    content: property.title,
    title: property.title,
    costPerNight: property.costPerNight,
    createdAt: property.createdAt,
    propertyId: property.id,
    updatedAt: property.updatedAt,
    userId: property.userId
  }))
);

const currentYear = new Date().getFullYear();
const startYear = 2023;

export const yearsSelectoptions = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
  const year = startYear + index;
  return { name: year.toString(), value: year.toString() };
});

export const focusTimeline = (timeline, date) => {
  const timeoutId = setTimeout(() => {
    timeline?.moveTo(date)
  }, 500);

  return () => {
    clearTimeout(timeoutId);
  };
};

export const addMonthToDate = (date, monthToAdd) => {
  if (!date) return;

  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() + monthToAdd);

  return dateCopy;
};

export const getObjectById = (arrayOfObjects, id) => { // TODO: use getObjectByValue from utils.js
  if (arrayOfObjects?.length) {
    return arrayOfObjects.find(obj => obj.id === id);
  }

  return;
};

const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const isDateChanged = (originDates, changedDates) => {
  const originStartDate = resetTime(new Date(originDates.start)).getTime();
  const originEndDate = resetTime(new Date(originDates.end)).getTime();
  const changedStartDate = resetTime(new Date(changedDates.start)).getTime();
  const changedEndDate = resetTime(new Date(changedDates.end)).getTime();

  // Compare date components of start and end dates
  const isStartDateChanged = originStartDate !== changedStartDate;
  const isEndDateChanged = originEndDate !== changedEndDate;

  return isStartDateChanged || isEndDateChanged;
};

export const isDaysAmountChanged = (originDates, changedDates) => {
  // Convert the dates to Date objects and reset the time part
  const originStartDate = resetTime(new Date(originDates.start));
  const originEndDate = resetTime(new Date(originDates.end));
  const changedStartDate = resetTime(new Date(changedDates.start));
  const changedEndDate = resetTime(new Date(changedDates.end));

  // Calculate the difference in days between the start and end dates
  const getDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Hours*Minutes*Seconds*Milliseconds
    return Math.round((endDate - startDate) / oneDay);
  };

  const originDaysDifference = getDaysDifference(originStartDate, originEndDate);
  const changedDaysDifference = getDaysDifference(changedStartDate, changedEndDate);

  // Compare the days differences
  return originDaysDifference !== changedDaysDifference;
};

export const findProperyIdByName = (arrayOfObjects, propertyName) => {
  return arrayOfObjects.find(obj => obj.title === propertyName);
};