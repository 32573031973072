import axios from 'axios';

const userApi = {
  getUserData: async () => {
    const res = await axios.get('/api/user/get-user-data');
  
    return res.data || [];
  },

  editUser: async (data) => {
    const res = await axios.patch('/api/user/edit-user-data', data);

    return res.data || [];
  },

  removeAccount: async () => {
    const res = await axios.delete('/api/user/remove-account');
    
    return res.data || [];
  }
};

export default userApi;
