import React from "react";
import PropTypes from 'prop-types';
import { MdDeleteForever } from "react-icons/md";
import { fetchData } from '../../common/commonRequests'; 
import coworkerAPI from '../../../services/coworkerAPI';

const CoworkerItem = ({ item, translate, reloadData, setIsLoading, setError }) => {
  const handleRemove = () => {
    fetchData(
      setIsLoading,
      setError,
      coworkerAPI.removeCoworker,
      item.id,
      reloadData
    );
  };

  return (
    <div key={item.id} className="coworkers-list-item">
      <div className="item-info">
        <p>{item.requestedTo} - [ {translate(item.role)} ]</p>
        <p><span className="statusLabel">{translate('status')} - </span> {translate(item.status)}</p>
      </div>
      <MdDeleteForever
        className="controls-icons"
        size={'1.5em'}
        onClick={handleRemove}
        title={translate('remove')}
      />
    </div>
  );
};

const CoworkerList = ({ coworkers, translate, reloadData, setIsLoading, setError }) => (
  <div className="coworkers-list">
    {coworkers?.length ? <h6>{translate('accessGranted')}</h6> : null}
    {coworkers?.map((item) => (
      <CoworkerItem
        key={item.id}
        item={item}
        translate={translate}
        coworkers={coworkers}
        reloadData={reloadData}
        setIsLoading={setIsLoading}
        setError={setError}
      />
    ))}
</div>
);

CoworkerList.propTypes = {
  translate: PropTypes.func.isRequired,
  coworkers: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      email: PropTypes.string,
      _id: PropTypes.string
    })
  ),
  reloadData: PropTypes.func,
  setIsLoading: PropTypes.func,
  setError: PropTypes.func
};

CoworkerItem.propTypes = {
  item: PropTypes.shape({
    role: PropTypes.string,
    email: PropTypes.string,
    _id: PropTypes.string
  }),
  translate: PropTypes.func.isRequired,
  reloadData: PropTypes.func,
  setIsLoading: PropTypes.func,
  setError: PropTypes.func
};

export default CoworkerList;
