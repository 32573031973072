import React, { useState } from "react";
import PropTypes from 'prop-types';
import { supportedLanguages } from '../common/utils';
import SelectComponent from "../common/SelectComponent";

const ChangeLanguage = ({ handleSaveEditData, selectedLocale, translate }) => {

  const handleLanguageSelect = (e) => {
    handleSaveEditData({ selectedLocale: e.target.value })
  };

  return (
    <div className="change-language-wrap">
      <div className="change-language">
        <SelectComponent
          options={supportedLanguages}
          handleSelect={handleLanguageSelect}
          label={translate('changeLanguage')}
          value={selectedLocale}
        />
      </div>
    </div>
  );
};

ChangeLanguage.propTypes = {
  handleSaveEditData: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default ChangeLanguage;
