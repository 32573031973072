// const todayDate = new Date();
// const inOneWeekDate = new Date(new Date().setDate(todayDate.getDate() + 7));

// let oneYearFromToday = new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));

// const ItemTemplate = ({ item }) => {
//   console.log("--ItemTemplate-122-", item)
//     return (
//       <div className="timeline-item-wrap">
//         <label>{item.content}</label>
//         <button
//           onClick={() => {
//             return console.log("--!--saaaaaa", item);
//           }}
//         >
//           aaaas
//         </button>
//       </div>
//     );
// };

// const GroupTemplate = ({ group }) => {
//   console.log("--group", group);
//   return (
//     <div>
//       <label>{}</label>
//       <button
//           onClick={() => {
//             return console.log("--!--saaaaaa");
//           }}
//         >
//           aaaa
//         </button>
//     </div>
//   );
// };

// class VisibleFramTemplate extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     return (
//       <div>VisibleFramTemplate
//         id: {this.props.item.id}
//         <button
//           onClick={() => {
//             return console.log("aCCCCCCCC");
//           }}
//         >
//           CCCCCCCCCCCCCCCC
//         </button>
//       </div>
//     );
//   }
// };

export const options = {
  // locale: 'uk',
  // height: window.innerHeight - 40,
  // height: '800px',
  // maxHeight: '800px',
  stack: false, // Items overlaping
  showMajorLabels: true,
  showCurrentTime: true,
  zoomMin: 1000000000, // 10000000000 - 3 month,
  zoomMax: 3000000000,
  format: {
    minorLabels: {
      minute: 'h:mma',
      hour: 'ha'
    }
  },
  margin: { // TODO: Check
    axis: 5,
    item: {
      vertical: 5,
      horizontal: 0
    }
  },
  orientation: { // TODO: Check
    axis: "both",
    item: "top"
  },
  // orientation: "top",
  // start: todayDate, // Min date in visible screen. If no "start" date - don't move to today date before mooving another date
  // end: inOneWeekDate,
  // min: appStartDate,      // lower limit of visible range
  // max: oneYearFromToday,  // upper limit of visible range
  multiselect: true,
  type: 'range', // background, point
  // type: 'background',
  // editable: true,
  // moveable: true,
  selectable: true,
  // itemsAlwaysDraggable: true,
  // groupEditable: true,
  // zoomable: false,
  editable: {
    // add: true,         // add new items by double tapping
    updateTime: true,  // drag items horizontally
    updateGroup: true, // drag items from one group to another
    remove: false,       // delete an item by tapping the delete button top right
    overrideItems: false  // allow these options to override item.editable
  }
  // template: function (item, element) {
  //   if (!item) {
  //     return;
  //   }

  //   if (!root) {
  //     root = createRoot(element);
  //   }

  //   root.render(<ItemTemplate item={item} />);
  //   return '';
  // }
  // groupTemplate: function (group, element) {
  //   if (!group || !group.content) {
  //     return;
  //   }

  //   if (!root) {
  //     root = createRoot(element);
  //   }

  //   root.render(<GroupTemplate group={group} />);
  //   return '';
  // }
};

