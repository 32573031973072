import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// If one Request approved and another request pending,
// display message to cancel current connection and than approve the new one.
const CoworkerRequestModalMessage = ({ translate, isNewRequest, requesteBy, workSpaceEmail }) => {
  const requestForNoCoworker = `${translate('coworkerRequestMessage')} - ${requesteBy}`;

  return (
    isNewRequest() ? (
      <div>
        <p>{ requestForNoCoworker }</p>
        <p>{ translate('cancelBeforeAcceptRequest') } { workSpaceEmail }</p>
        <p>{ translate('inTheSettingsSection') }</p>
      </div>
    ) : (
      <p>{ requestForNoCoworker }</p>
    )
  );
};

CoworkerRequestModalMessage.propTypes = {
  translate: PropTypes.func,
  isNewRequest: PropTypes.func,
  requesteBy: PropTypes.string,
  workSpaceEmail: PropTypes.string
};

export default CoworkerRequestModalMessage;
