import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { TextInput, Button } from "react-materialize";
import M from 'materialize-css';
import coworkerAPI from '../../../services/coworkerAPI';
import { useFetch } from '../../common/fetchData.hooks';
import { fetchData } from '../../common/commonRequests';
import { getRequiredInputParams, coworkerRequestStatus } from '../../common/utils';
import { roleDefault, getRolesOptions } from '../../common/utils';
import { isUserEmailExists } from '../settingsUtils';
import SelectComponent from '../../common/SelectComponent';
import CoworkersList from './CoworkersList';

// TODO: on backend:
// 1. Get data by "requestedTo" key
// 2. If "data.status" is "Confirmed" - store "requestedBy" as "parentAccountEmail" on backend variables.
// 3. Copy user settings from parrent (Display feeding option, Premium account)
// 4. Add logic to Orders requests to use "parentAccountEmail" as userEmail. 

// On UI:
// Compare "requestedTo" vs "user email", if true - get data and see status.
// if status "pending" - show modal.
// In modal click "Approve", "Decline" or Close modal.
// Save "Approve" status or remove request if status is "Decline".
// Hide some settings for Coworker.

const AddCoworkers = ({ nickname, translate, setIsLoading, setError }) => {
  const initCoworkerData = {
    requestedByNickname: nickname,
    role: roleDefault,
    requestedTo: '',
    status: coworkerRequestStatus.pending
  };

  const [newCoworker, setNewCoworker] = useState({
    ...initCoworkerData
  });
  const [message, setMessage] = useState(null);

  const { data: allCoworkers, isLoading, error, reloadData } = useFetch(coworkerAPI.getCoworkerData);

  const handleInputs = (data) => {
    const key = Object.keys(data)[0];

    setNewCoworker(prevState => (
      {
        ...prevState,
        [key]: data[key]
      }
    ))
  };

  const handleRoleSelect = (e) => {
    setNewCoworker(prevState => (
      {
        ...prevState,
        role: e.target.value
      }
    ))
  };

  const saveCoworkerCallback = () => {
    setNewCoworker(initCoworkerData);
    reloadData();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isCoworkerAlreadyAdded =  isUserEmailExists(newCoworker.requestedTo, allCoworkers);

    if (isCoworkerAlreadyAdded) {
      setMessage(translate('coworkerAlreadyAdded'));
    } else {
      fetchData(
        setIsLoading,
        setError,
        coworkerAPI.saveCoworker,
        newCoworker,
        saveCoworkerCallback
      );
    };
  };

  // Display the popup message
  const displayToast = (message) => {
    M.toast({ html: message, displayLength: 5000 });
  };

  useEffect(() => {
    if (message) {
      displayToast(message);
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {
    setIsLoading(isLoading);
    setError(error)
  }, [setIsLoading, setError]);

  return (
    <div className="add-new-coworkers-wrap">
      <form onSubmit={handleSubmit}> 
        <TextInput
          email
          value={newCoworker?.requestedTo}
          onChange = {(e) => handleInputs({ requestedTo: e.target.value })}
          label={translate('email')}
          id="add-coworker-email"
          maxLength="30"
          {...getRequiredInputParams(translate('wrongEmailFormat'))}
        />
        <SelectComponent
          options={getRolesOptions(translate)}
          handleSelect={handleRoleSelect}
          label={translate('role')}
          value={newCoworker?.role}
        />
        <Button
            node="button"
            type="submit"
            disabled={!newCoworker.requestedTo.length}
        >
            {translate('add')}
        </Button>
      </form>

      <CoworkersList
        coworkers={allCoworkers}
        translate={translate}
        reloadData={reloadData}
        setIsLoading={setIsLoading}
        setError={setError}
      />
    </div>
  )
};

AddCoworkers.propTypes = {
  translate: PropTypes.func.isRequired,
  nickname: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default AddCoworkers;
