import axios from 'axios';

const coworkerApi = {
  saveCoworker: async (data) => {
    const res = await axios.post('/api/coworker/save-coworker', data);

    return res.data || [];
  },

  getCoworkerData: async () => { // RequestedBy - Get sent requests coworkers list for parent
    const res = await axios.get('/api/coworker/get-coworker-data');
  
    return res.data || [];
  },

  getCoworkerRequests: async () => { // RequestedTo - Get requests list to be accepted by coworker
    const res = await axios.get('/api/coworker/get-coworker-requests');
  
    return res.data || [];
  },

  removeCoworker: async (id) => {
    const res = await axios.delete('/api/coworker/remove-coworker', { data: { coworkerId: id } });

    return res.data || [];
  },

  editCoworker: async (requestData) => { // TODO refactor all edit-patch requests to be used with common Fetch request
    const id = requestData.id;
    const data = requestData.data;
    const res = await axios.patch('/api/coworker/edit-coworker', { id, data });

    return res.data || [];
  },

  removeAllCoworkerRequests: async () => {
    const res = await axios.delete('/api/coworker/remove-all-requests');
    
    return res.data || [];
  }
};

export default coworkerApi;

// id: pendingRequests[0].id,
// data: {
//   status: coworkerRequestStatus.approved
// }
