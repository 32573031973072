import { handleError } from './utils';

export const fetchData = async (
  setIsLoading,
  setError,
  request,
  data,
  callback
) => {
  console.log("--request-1-", request)
  setIsLoading(true);
  setError({});
  console.log('Loading started-2');
  try {
    await request(data);
    console.log('Request completed-3');
    if (callback) {
      console.log('Calling callback-4');
      callback(); // await callback();
    }
  } catch (error) {
    handleError(
      error.response?.status,
      error.response?.statusText,
      error.response?.data.message,
      setError
    );
  } finally {
    setIsLoading(false);
    console.log('Loading finished-5');
  }
};


// Data structure for Edit:
// {
//   id: '',
//   data: {
//     status: 'REJECTED'
//   }
// }



// export const removeData = async (setIsLoading, setError, request, callback) => {
//   setIsLoading(true);
//   setError({});  
//   try {
//       await request();
      
//       if (callback) {
//         callback();
//       }
//     } catch (error) {
//       handleError(
//         error.response?.status,
//         error.response?.statusText,
//         error.response?.data.message,
//         setError
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };
