import { useState, useEffect, useCallback } from "react";
import { handleError } from './utils';

export const useFetch = (request) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const fetchData = useCallback( async () => {
    setIsLoading(true);
    setError({});

    try {
      const fetchedData = await request();
      setData(fetchedData);
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  }, [request]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // TODO: add async await???
  // Check all usage of "useFetch" with "reloadData" to make sure it called with await???
  const reloadData = () => {
    console.log("---Reload--covorkers--")
    fetchData();
  };

  // const reloadData = async () => {
  //   await fetchData();
  // };

  return { data, isLoading, error, reloadData };
};

export const useGetOrders = (request, params) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const fetchOrderData = async () => {
    setIsLoading(true);
    setError({});

    try {
      const fetchedData = await request(params);

      setData(fetchedData);
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  };

  const reloadData = () => {
    fetchOrderData();
  };

  return { data, isLoading, error, reloadData };
};

// hooks loads data on component load, so "setIsEditDataRequest" controll request send
export const useEdit = (request, data, callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isEditDataRequest, setIsEditDataRequest] = useState(false);

  const editData = async () => {
    setIsLoading(true);
    setError({});

    try {
      await request(data);
      callback && callback();
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditDataRequest) {
      editData();
      setIsEditDataRequest(false);
    }
  }, [editData, isEditDataRequest, request, callback]);

  const sendEditDataRequest = () => { // To controll when request should be trigered
    setIsEditDataRequest(true);
  };

  return { isLoading, error, sendEditDataRequest };
};
