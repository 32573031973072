import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PropertyItem from './PropertyItem';
import PropertyItemEdit from './PropertyItemEdit';

const PropertyItemComponent = ({
  title,
  costPerNight,
  description,
  id,
  reloadPropertiesList,
  isAvailable
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleEditSuccess = () => {
    setIsEdit(false);
    reloadPropertiesList();
  };

  return (
    <>
      {isEdit ? (
        <PropertyItemEdit
          propertyId={id}
          title={title}
          costPerNight={costPerNight}
          description={description}
          isAvailable={isAvailable}
          setIsEdit={setIsEdit}
          onEditSuccess={handleEditSuccess}
        />
      ): (
        <PropertyItem
          id={id}
          title={title}
          costPerNight={costPerNight}
          description={description}
          reloadPropertiesList={reloadPropertiesList}
          setIsEdit={setIsEdit}
        />
      )}
    </>
  );
};

PropertyItemComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  costPerNight: PropTypes.number.isRequired,
  reloadPropertiesList: PropTypes.func.isRequired,
  description: PropTypes.string
};

PropertyItemComponent.defaultProps = {
  description: ''
};

export default PropertyItemComponent;