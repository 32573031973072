import React, { useState } from "react";
import PropTypes from 'prop-types';
import { MdDeleteForever } from "react-icons/md";
import { CiEdit } from 'react-icons/ci';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import settingsAPI from '../../../services/settingsAPI';
import { handleError } from '../../common/utils';
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';

const PropertyItem = ({
    title,
    costPerNight,
    description,
    id,
    reloadPropertiesList,
    setIsEdit
  }) => {
  const { translate } = useUserDataContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isControls, setIsControls] = useState(false);

  const removeItem = async () => { // TODO: remove all related orders also (see RemoveAccount.jsx)
    try {
      setIsLoading(true);
      setError({});
      await settingsAPI.removeProperty(id);
      reloadPropertiesList();
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = () => {
    removeItem();
  };

  return (
    <>
      <Loader 
        setIsLoading={isLoading}
      />
      <ErrorAlert
        error={error}
      />
      <div
        className="property-item-wrap"
        onMouseEnter={() => setIsControls(true)}
        onMouseLeave={() => setIsControls(false)}
      >
        <div className="property-item-content">
          <div className="property-item-content-title">
            <p>{translate('title')}:</p>
            <p>{translate('costNight')}:</p>
            <p>{translate('description')}:</p>
          </div>
          <div className="property-item-content-value">
            <p>{ title }</p>
            <p>{ costPerNight }</p>
            <p className="property-item-description">{ description }</p>
          </div>
        </div>
        <div className="property-item-controls">
          {isControls && (
            <>
              <CiEdit
                className="controls-icons"
                size={'1.7em'}
                onClick={() => setIsEdit(true)}
                title={translate('edit')}
              />
              <MdDeleteForever
                className="controls-icons"
                size={'1.5em'}
                onClick={handleRemove}
                title={translate('remove')}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

PropertyItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  costPerNight: PropTypes.number.isRequired,
  reloadPropertiesList: PropTypes.func.isRequired,
  description: PropTypes.string
};

PropertyItem.defaultProps = {
  description: ''
};

export default PropertyItem;