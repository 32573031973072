import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import { TextInput, Button } from "react-materialize";
import { getRequiredInputParams } from '../../common/utils';
import settingsAPI from '../../../services/settingsAPI';
import { handleError } from '../../common/utils';
import ModalComponent from '../../common/ModalComponent';
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';

const AddNewProperty = ({ reloadPropertiesList, allProperties = [] }) => {
  const { translate } = useUserDataContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [newItem, setNewItem] = useState({
    title: '',
    costPerNight: '',
    isAvailable: true
  });
  const [isSameTitleModal, setIsSameTitleModal] = useState(false);

  const handleDataSet = (data) => {
    const key = Object.keys(data)[0];

    setNewItem(prevState => (
      {
        ...prevState,
        [key]: data[key]
      }
    ))
  };

  const isTitleExists = (titleToCheck, allProperties) => {
    return allProperties.some(item => item.title === titleToCheck);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isTitleExists(newItem.title, allProperties)) {
      setIsSameTitleModal(true);

      return;
    }

    try {
      setIsLoading(true);
      setError({});
      await settingsAPI.addNewProperty(newItem);
      reloadPropertiesList();
    } catch (error) {      
      handleError(
        error.response?.status,
        error.response?.statusText,
        error.response?.data.message,
        setError
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings-add-new-item-wrap">
      <Loader 
        setIsLoading={isLoading}
      />
      <ModalComponent 
        ModalContent={translate('thisNameAlreadyExist')}
        show={isSameTitleModal}
        hideCancelButton
        type="WARNING"
        handleClose={() => setIsSameTitleModal(false)}
      />
      <ErrorAlert
        error={error}
      />
      <form onSubmit={handleSubmit} className="add-new-item-form"> 
        <TextInput
          value={newItem.title}
          onChange = {(e) => handleDataSet({ title: e.target.value })}
          label={translate('title')}
          id="itemTitle"
          maxLength="30"
          {...getRequiredInputParams(translate('canNotBeEmpty'))}
        />
        <TextInput
          value={newItem.costPerNight}
          onChange = {(e) => handleDataSet({ costPerNight: e.target.value })}
          label={translate('costNight')}
          id="itemCostPerNight"
          maxLength="8"
          type="number"
          min="0"
          max="100000"
          error={translate('wrongNumberOrEmpty')}
          required={true}
          validate={true}
        />
        <TextInput
          value={newItem.description}
          onChange = {(e) => handleDataSet({ description: e.target.value })}
          label={translate('description')}
          id="itemDescription"
          maxLength="150"
        />
        <Button
            node="button"
            waves="light"
            type="submit"
        >
            {translate('add')}
        </Button>
      </form>
    </div>
  )
};

AddNewProperty.propTypes = {
  reloadPropertiesList: PropTypes.func.isRequired,
  allProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      costPerNight: PropTypes.number.isRequired,
      description: PropTypes.string,
      isAvailable: PropTypes.bool
  }))
};

export default AddNewProperty;