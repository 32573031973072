import React, { useState, useEffect } from "react";
import { useUserDataContext } from '../../contexts/userDataProvider';
import { Button } from "react-materialize";
import ordersAPI from '../../services/ordersAPI';
import { usePropertyContext } from '../../contexts/propertyProvider';
import { useGetOrders } from '../common/fetchData.hooks';
import { todayDate, inOneWeekDate, getOrderStatusesOptions, initStatus } from '../common/utils';
import DatePickerComponent from '../common/DatePickerComponent';
import SelectComponent from '../common/SelectComponent';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import CardOrderComponent from './CardOrderComponent';

const Search = () => {
  const { translate } = useUserDataContext();

  const [selectedDate, setSelectedDate] = useState({
    dateFrom: todayDate,
    dateTo: inOneWeekDate,
  });
  const [orderType, setOrderType] = useState(initStatus);

  const {
    data: propertyData,
    isLoading: isPropertyLoading,
    error: errorProperty,
  } = usePropertyContext([]);

  const { data: orders, isLoading: isOrderLoading, error: errorOrder, reloadData } = useGetOrders(
    ordersAPI.getOrders,
    {selectedDate, orderType}
  );
 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const handleSelectType = (e) => {
    setOrderType(e.target.value)
  };

  const handleDatePicker = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const newState = {...selectedDate};
    newState[key] = val;

    setSelectedDate(newState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    reloadData();
  };

  const reloadOrders = () => {
    reloadData();
  }

  useEffect(() => {
    reloadData();
  }, []); // "reloadData" can't be added, because of infinity reloading

  return (
    <>
      <Loader 
        setIsLoading={isLoading || isOrderLoading || isPropertyLoading}
      />
      <ErrorAlert
        error={error || errorOrder || errorProperty}
      />
      {propertyData?.length ? (
        <>
          <form onSubmit={handleSubmit} className="filters-wrap">
            <DatePickerComponent
              handleChange={handleDatePicker}
              selectedDate={selectedDate}
            />
            <SelectComponent
              options={getOrderStatusesOptions(translate)}
              handleSelect={handleSelectType}
              label={translate('selectStatus')}
            />
            <Button
              className="submit-button"
              node="button"
              type="submit"
            >
              {translate('search')}
            </Button>
          </form>
          <div className="card-wrap">
            {orders.map((order) => (
              <CardOrderComponent
                key={order.id}
                orderData={order}
                setIsLoading={setIsLoading}
                setError={setError}
                reloadOrders={reloadOrders}
              />
            ))}
            {orders.length === 0 && <div className="nothing-found">{translate('nothingFound')}</div>}
          </div>
        </>
      ) : ''}
    </>
  );
};

export default Search;
