import React, { useState } from "react";
import { Button } from "react-materialize";
import { translations, loginLink } from './common/utils';

const Login = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const translate = (key) => translations[selectedLanguage][key];

  const selectLanguageHandler = (lang) => {
    console.log(lang);
    setSelectedLanguage(lang)
  };

  return (
    <div className="login-wrap">
      <div className="login-lang-select">
        <Button
          className="lang-button"
          node="button"
          waves="light"
          onClick={() => selectLanguageHandler('en')}
        >
          (EN) English
        </Button>
        <Button
          className="lang-button"
          node="button"
          waves="light"
          onClick={() => selectLanguageHandler('uk')}
        >
          (UA) Українська
        </Button>
      </div>
      <div className="login-title">
        <h1>{translate('landingTitle')}</h1>
      </div>
      <Button
        className="login-button"
        node="button"
        waves="light"
        onClick={() => window.location.href = loginLink}
      >
        {translate('login')}
      </Button>
    </div>
  )
};

export default Login;
