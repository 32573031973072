import PropTypes from 'prop-types';
import { useUserDataContext } from '../../contexts/userDataProvider';
import Alert from './Alert';
// import { logOutUrl } from './utils';

const ErrorMessage = ({ error }) => {
  const { translate } = useUserDataContext();
  // const unauthorized = error.status === 401;

  return (
    // unauthorized // TODO: change error message for not authorized, add styles
      // ? (<>
      //     <p>{translate('authSessionHasExpired')}</p>
      //     <a href={logOutUrl}>{translate('backToLoginPage')}</a>
      //   </>)
      // : (
      //   <>
      //     <p>{translate('errorMessageComon')}</p>
      //     <p>{error.status} - {error.statusText}</p>
      //     <p>{error.message}</p>
      //   </>
      // )
    <>
      <p>{translate('errorMessageComon')}</p>
      <p>{error.status} - {error.statusText}</p>
      <p>{error.message}</p>
    </>
  )
};

const ErrorAlert = ({ error, className }) => {

  return (
    <Alert
      dismissible={error.status !== 401}
      isAlert={!!Object.keys(error).length}
      variant={'error'}
      children={<ErrorMessage error={error} />}
      className={className}
    />
  );
};

ErrorAlert.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
    message: PropTypes.string
  })
};

export default ErrorAlert;
