import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-materialize";
import { useUserDataContext } from '../contexts/userDataProvider';
import { logOutUrl } from './common/utils';

const NotActivated = () => {
  const navigate = useNavigate();
  const { translate } = useUserDataContext();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  };

  const backButton = {
    marginTop: 60
  };

  return (
    <div style={containerStyle}>
      <h3>{translate('notActivatedMessage')}</h3>
      <Button
        style={backButton}
        node="button"
        type="submit"
        waves="light"
        onClick={() => navigate(logOutUrl)}
      >
        {translate('backToLoginPage')}
      </Button>
    </div>
  );
};

export default NotActivated;
