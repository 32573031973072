import React, { useState } from 'react';
import { Button, Checkbox, Tabs, Tab } from 'react-materialize';
import { useUserDataContext } from '../../contexts/userDataProvider';
import userAPI from '../../services/userAPI';
import { useEdit } from '../common/fetchData.hooks';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import TermsEn from './TermsEn';
import TermsUa from './TermsUa';

const TermsAndConditions = () => {
  const { data } = useUserDataContext();
  const { selectedLocale } = data ? data[0] : ''; // Get locale to init Tab if 'selectedLocale' already setted

  const [isAgree, setIsAgree] = useState(false);
  const [langTabSelected, setLangTabSelected] = useState(selectedLocale || 'en');

  const { isLoading, error, sendEditDataRequest } = useEdit(
    (data) => userAPI.editUser(data),
    { isTermsAccepted: isAgree, selectedLocale: langTabSelected },
    () => window.location.reload()
  );

  const handleSelectLanguageTabCick = (e) => {
    setLangTabSelected(e.target.href.split('_')[1]); // Get index locale from "Tab"
  };

  const getLocale = (key) => {
    const translations = {
      en: {
        iAcceptTermsAndConditions: 'I accept the terms and conditions',
        submit: 'Submit'
      },
      uk: {
        iAcceptTermsAndConditions: 'Я приймаю положення та умови',
        submit: 'Надіслати'
      }
    };

    return translations[langTabSelected][key];
  };

  const handleSubmit = () => {
    sendEditDataRequest();
  };

  return (
    <div className="terms-and-conditions-wrap">
      <Loader 
        setIsLoading={isLoading}
      />
      <ErrorAlert
        error={error}
      />

      <div className="accept-form">
        <Checkbox
          label={getLocale('iAcceptTermsAndConditions')}
          value={isAgree}
          onChange={() => setIsAgree(!isAgree)}
        />
        <Button
         className="accept-button"
          node="button"
          waves="light"
          type="button"
          disabled={!isAgree}
          onClick={handleSubmit}
        >
          {getLocale('submit')}
        </Button>
      </div>

      <Tabs
        className="tabs z-depth-1 tabs-fixed-width"
        onChange={handleSelectLanguageTabCick}
      >
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="(EN) English"
          idx="en"
          active={selectedLocale === 'en'}
        >
          <TermsEn />
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false
          }}
          title="(UA) Українська"
          idx="uk"
          active={selectedLocale === 'uk'}
        >
          <TermsUa />
        </Tab>
      </Tabs>
    </div>
  );
};

export default TermsAndConditions;
