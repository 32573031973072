import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useUserDataContext } from '../contexts/userDataProvider';
import { useCoworkerRequestContext } from '../contexts/coworkerRequestProvider';
import Loader from './common/Loader';
import ErrorAlert from './common/ErrorAlert';
import { logOutUrl } from './common/utils';

const Header = ({ setIsTermsAcceptedInDb, setUserStatus }) => {
  const { translate, data, isLoading, error } = useUserDataContext();
  const { nickname, isTermsAccepted, userStatus } = data ? data[0] : '';

  const {
    data: allCoworkerRequests,
    isLoading: isLoadingCoworkerData,
    error: errorCoworkerData,
    isApprovedCoworker,
    workSpaceEmail
  } = useCoworkerRequestContext();

  useEffect(() => {
    if (data) {
      setIsTermsAcceptedInDb(isTermsAccepted);
      setUserStatus(userStatus);
    }
  }, [data, isTermsAccepted, setIsTermsAcceptedInDb, userStatus, setUserStatus]);

  return (
    <div className="header-container">
      <div className="header-wrap">
        <div className="right-layout">
          <p className="nickname">{nickname} {isApprovedCoworker && `(${workSpaceEmail})`}</p>
          <a className="logout-button" href={logOutUrl}>{translate('logout')}</a>
        </div>
      </div>
      <Loader 
        setIsLoading={isLoading || isLoadingCoworkerData} // TODO: Moove loader to App.js to prevent TermAndConditions render on each load
      />
      <ErrorAlert
        error={error || errorCoworkerData}
        className={'header-error-alert'}
      />
    </div>
  );
};

Header.propTypes = {
  setIsTermsAccepted: PropTypes.func,
  setUserStatus: PropTypes.func
};

Header.defaultProps = {
  setIsTermsAccepted: undefined,
  setUserStatus: undefined
};

export default Header;
