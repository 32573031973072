import moment from 'moment';
import ordersAPI from '../../services/ordersAPI';
import { handleError, addOneDayToDate } from './utils';

const calculateNights = (startDate, endDate) => {
  const differenceDays = moment(endDate).diff(moment(startDate), 'days');

  return differenceDays;
};

export const getTotalCost = (costPerNight, orderDates) => {
  let startDate;
  let endDate;

  if (Array.isArray(orderDates)) { // TODO: remove usage of "orderDates" as array and remove this condition. Used only in AddNewOrder.jsx
    startDate = orderDates[0].startDate;
    endDate = orderDates[0].endDate;
  } else {
    startDate = orderDates.startDate;
    endDate = orderDates.endDate;
  }

  const totalCost = startDate && endDate && new Date(startDate).getTime() < new Date(endDate).getTime()
    ? costPerNight * calculateNights(startDate, endDate)
    : 0;

  return totalCost;
};

export const getPropertyObjByTitle = (propertyData, title) => (  // TODO: use getObjectByValue from utils.js
  propertyData?.find(property => property.title === title)
);

export const getOrderObjById = (ordersData, id) => (  // TODO: use getObjectByValue from utils.js
  ordersData?.find(order => order.id === id)
);

// Place item on TimeLine always at the center (12:00) regardless of the clicked point.
export const fitTimeInDate = (date) => { // TODO: dateType?
  const time = 0o5; //dateType?.isEndDay ? 12 : 0o1;

  date.setHours(time);
  date.setMinutes(0);
  date.setSeconds(0);

  return date;
};

// -- TimeLine - get end date

// const formatDate = (date) => {
//   const day = date.getDate();
//   const month = date.getMonth() + 1;
//   const year = date.getFullYear();
  
//   return `${year}-${month}-${day}`;
// };

// const isDateExistInOrdersData = (ordersData, date, propertyId) => {
//   const formattedDateTo = formatDate(date);

//   return ordersData.some(order => {
//     const orderDate = new Date(order.dateFrom);
//     const formattedOrderDate = formatDate(orderDate);

//     return formattedOrderDate === formattedDateTo && order.propertyId === propertyId;
//   });
// }

// // If the next day of clicked day is reserved, return same date as "dateTo" or +1 day if the next day is available
// export const getTimeLineEndDate = (ordersData, clickedDay, clickedPropertyId) => {
//   let dateTo = addOneDayToDate(clickedDay);

//   if (isDateExistInOrdersData(ordersData, clickedDay, clickedPropertyId)) { // if next day from clicked is reserved
//     dateTo = clickedDay;
//   }

//   return dateTo;
// }
