import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "react-materialize";
import { useUserDataContext } from '../../contexts/userDataProvider';
import { usePropertyContext } from '../../contexts/propertyProvider';
import { fetchData } from '../common/commonRequests';
import ModalComponent from '../common/ModalComponent';
import settingsAPI from '../../services/settingsAPI';
import ordersAPI from '../../services/ordersAPI';
import userApi from "../../services/userAPI";
import coworkerApi from "../../services/coworkerAPI";

const RemoveAccount = ({ setIsLoading, setLocalError, workSpaceEmail }) => {
  const {
    data: propertyData
  } = usePropertyContext();
  const { translate } = useUserDataContext();

  const [isRemoveDataModal, setIsRemoveDataModal] = useState(false);
  const [isRemoveAccountModal, setIsRemoveAccountModal] = useState(false);

  const handleReload = () => {
    window.location.reload();
  };

  const removeOrdersCallback = () => {
    setIsRemoveDataModal(false);
    handleReload(); // Can't use "reloadPropertiesData();" from useUserDataContext, because TimeLine doesn't detect changes in groups array
  };

  const removeAccountCallback = () => {
    handleReload();
  };

  // TODO: show returned records amount from BE to display in confimation message
  const removeData = async () => {
      await fetchData(setIsLoading, setLocalError, ordersAPI.removeAllOrders);
      await fetchData(setIsLoading, setLocalError, settingsAPI.removeAllProperties, undefined, removeOrdersCallback);
  };

  const removeAccount = async () => {
    await fetchData(setIsLoading, setLocalError, userApi.removeAccount);
    await fetchData(setIsLoading, setLocalError, coworkerApi.removeAllCoworkerRequests, undefined, removeAccountCallback);
  };

  return (
    <div className="remove-account-wrap">
      <h5>{translate('removeAccount')}</h5>
      <ModalComponent 
        ModalContent={
          isRemoveAccountModal
          ? translate('yourAccountWillBeRemoved')
          : translate('allRecordsWillBeRemoved')
        }
        onSubmitButtonHandler={isRemoveAccountModal ? removeAccount : removeData}
        onSubmitButtonName={translate('yesRemove')}
        show={isRemoveDataModal || isRemoveAccountModal}
        handleClose={
          () => isRemoveAccountModal
          ? setIsRemoveAccountModal(false)
          : setIsRemoveDataModal(false)
        }
        type="WARNING"
      />
      {workSpaceEmail && <p>{translate('removeAcceptedWorkspaceToContinue')}</p> }
      <Button
        node="button"
        waves="light"
        onClick={() => setIsRemoveDataModal(true)}
        disabled={workSpaceEmail || !propertyData?.length}
      >
        {translate('removeAllRecords')}
      </Button>
      <Button
        className="remove-account-btn"
        node="button"
        waves="light"
        onClick={() => setIsRemoveAccountModal(true)}
        disabled={!!propertyData?.length}
      >
        {translate('removeAccount')}
      </Button>
    </div>
  )
};

RemoveAccount.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setLocalError: PropTypes.func.isRequired,
  workSpaceEmail: PropTypes.string
};

export default RemoveAccount;
