import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { usePropertyContext } from '../../contexts/propertyProvider';

const PropertyList = ({ setData, timeLineSelectedElementId }) => {
  const { data } = usePropertyContext();
  const { translate } = useUserDataContext();
  const [selectedElement, setSelectedElement] = useState(''); // TODO: undefined?

  const handlePropertySelect = (id, title, costPerNight) => {
    setSelectedElement(id);
    setData({
      propertyName: title,
      propertyId: id,
      costPerNight: costPerNight
    })
  };

  // Select  property on edit
  useEffect(() => {
    if (timeLineSelectedElementId) {
      setSelectedElement(timeLineSelectedElementId);
    }
  }, [timeLineSelectedElementId]);

  return (
    <>
      <div className={'property-list-wrap'}>
        {data?.length === 0 ? (
          <div className="nothing-found">{translate('addFirstPropertyInSettings')}</div>
        ): (
          data?.map(({ id, title, costPerNight }) => (
            <div
              key={id}
              className="property-item"
              onClick={() => handlePropertySelect(id, title, costPerNight)}
            >
              <div
                title={title}
                className={`selectable-area ${
                  selectedElement === id || timeLineSelectedElementId === id ? 'selected' : ''
                }`}
              ></div>
              <div className="content">
                <div className="title">
                  <p>{translate('title')}:</p>
                  <p>{translate('costNight')}:</p>
                </div>
                <div className="value">
                  <p>{ title }</p>
                  <p>{ costPerNight }</p>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

PropertyList.propTypes = {
  setData: PropTypes.func.isRequired,
  timeLineSelectedElementId: PropTypes.string
};

PropertyList.defaultProps = {
  timeLineSelectedElementId: undefined
};

export default PropertyList;
